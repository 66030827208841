<template>
    <div class="container content" v-if="activeUser.type == 'admin'">
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <p class="title">Käyttäjärekisteri</p>
                    <div style="float: right; margin-top:35px">
                        <b-button size="is-medium"
                            @click="openCreateUserModal"
                            icon-left="plus">
                            Lisää uusi loppukäyttäjä
                        </b-button>
                    </div>
                    <b-field label="Haku">
                        <b-input
                            rounded
                            v-model="search"
                            placeholder="Haku"
                            icon="magnify"
                            clearable
                            @select="option => selected = option">
                            <template #empty>No results found</template>
                        </b-input>
                    </b-field>
                    <b-table
                        paginated
                        per-page="10"
                        @click="openCard"
                        :hoverable="true"
                        :data="filteredUsers"
                        :columns="columns">
                    </b-table>      
                </article>
            </div>     
        </div> 
        <b-modal
            v-model="modal"
            has-modal-card
            :can-cancel="true">
            <NewEndUserForm @userCreated="userCreated" @closeModal="modal = false"/>
        </b-modal> 
    </div>
</template>

<script>
import axios from 'axios';
import NewEndUserForm from '@/views/admin/forms/NewEndUser';
export default {
    components: {
        NewEndUserForm
    },
    data() {
        return {
            search: '',
            users: [],
            modal: false,
            columns: [
                {
                    field: 'type',
                    label: 'Käyttäjätyyppi'
                },
                {
                    field: 'first_name',
                    label: 'Etunimi'
                },
                {
                    field: 'last_name',
                    label: 'Sukunimi'
                },
                {
                    field: 'email',
                    label: 'Sähköpostiosoite'
                },
                {
                    field: 'mobile',
                    label: 'Puhelinnumero'
                },
                {
                    field: 'finpvm',
                    label: 'Tunnuksen luontiaika'
                },
            ]
        }
    },
    filters: {
        userType(userType) {
            if(userType == 'admin') {
                return "Ylläpitäjä"
            }
            if(userType == 'company') {
                return "Yrityskäyttäjä"
            }
            if(userType == 'education_partner') {
                return "Koulutuskumppani"
            }
            if(userType == 'enduser') {
                return "Loppukäyttäjä"
            }
            return userType;
        }
    },
    computed: {
        filteredUsers() {
            let filtered = [];
            this.users.forEach((user) => {
                user.type = this.$options.filters.userType(user.type);
                user.finpvm = this._formatDate(user.created_at);
                if(user.first_name.toLowerCase().includes(this.search.toLowerCase())) {
                    filtered.push(user);
                }
                else if(user.last_name.toLowerCase().includes(this.search.toLowerCase())) {
                    filtered.push(user);
                }
                else if(user.email.toLowerCase().includes(this.search.toLowerCase())) {
                    filtered.push(user);
                }
                else if(user.mobile.toLowerCase().includes(this.search.toLowerCase())) {
                    filtered.push(user);
                }
                else if(user.finpvm.includes(this.search)) {
                    filtered.push(user);
                }
                else if(this.search == '') {
                    filtered.push(user);
                }
            });
            return filtered;
        }
    },
    methods: {
        openCreateUserModal() {
            this.modal = true;
        },
        userCreated() {
            this.modal = false;
            this.getUsers();
        },
        getUsers() {
            axios.get('/users').then((response) => {
                this.users = response.data;
                console.log(response.data);
            })
        },
        openCard(data) {
            this.$router.push({ name: 'AdminUserRegisterUser', params: { userId: data.id }});
        }
    },
    mounted() {
        this.getUsers();
    }
}
</script>


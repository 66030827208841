<template>
    <div class="tile is-parent is-12">
        <article class="tile is-child box">
        <p class="title">Uusi loppukäyttäjä</p>
            <b-field
                :label="
                    $t('message.register-user.add.firstname')
                "
            >
                <b-input v-model="newUser.first_name"></b-input>
            </b-field>

            <b-field
                :label="
                    $t('message.register-user.add.lastname')
                "
            >
                <b-input v-model="newUser.last_name"></b-input>
            </b-field>

            <b-field
                :label="$t('message.register-user.add.email')"
                :type="emailFieldType"
            >
                <b-input
                    type="email"
                    value=""
                    v-model="newUser.email"
                    @blur="validateEmail"
                >
                </b-input>
            </b-field>
            <small v-if="validator.message.email != ''" class="errormessage">{{ validator.message.email }}</small>

            <b-field
                :label="$t('message.register-user.add.phone')"  :type="mobileFieldType"
            >
                <b-input @blur="validateMobile" v-model="newUser.mobile"></b-input>

            </b-field>
            <small v-if="validator.message.mobile != ''" class="errormessage">{{ validator.message.mobile }}</small>

            <b-field
                :label="
                    $t('message.register-user.add.password')
                "
            >
                <b-input
                    @blur="validateFirstPassword"
                    type="password"
                    value=""
                    v-model="newUser.password"
                    password-reveal
                >
                </b-input>
            </b-field>
            <password
                @input="validateFirstPassword"
                v-model="newUser.password"
                :strength-meter-only="true"
            />
            <b-field
                :label="
                    $t(
                        'message.register-user.add.confirm-password'
                    )
                "
                                                :type="passwordFieldType"
                :message="passwordFieldMessage"
            >
                <b-input
                    @blur="validatePassword"
                    type="password"
                    value=""
                    v-model="newUser.c_password"
                    password-reveal
                >
                </b-input>
            </b-field>
            <b-field label="Kieli">
                <b-select v-model="newUser.lang" placeholder="Valitse kieli">
                    <option value="fi">SUOMI</option>
                    <option value="en">ENGLANTI</option>
                </b-select>
            </b-field>
            <b-button
                :disabled="isDisabled"
                :loading="false"
                :label="$t('message.register-user.add.send')"
                size="is-large"
                class="fullwidth"
                type="is-success"
                v-on:click="register"
            />
        </article>
    </div>
</template>
<script>
import Password from "vue-password-strength-meter";
import axios from 'axios';
export default {
    components: {
        Password,
    },
    data() {
        return {
            user: false,
            newUser: {
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
                password: "",
                c_password: "",
                lang: "fi",
                type: "enduser",
                invite: false
            },
            errors: {
                is_error: false,
                first_name: false,
                last_name: false,
                email: false,
                mobile: false,
                password: false,
                c_password: false,
            },
            validator: {
                password: true,
                vat: true,
                email: true,
                mobile: true,
                message: {
                    email: "",
                    mobile: "",
                    password: "",
                    vat: ""
                }
            },
        }
    },
    computed: {
        isDisabled() {
            if(this.newUser.password.length < 6) {
                return "disabled";
            }
            if(!this.validator.password || !this.validator.password) {
                return "disabled";
            }
            if(!this.validator.email) {
                return "disabled";
            } 
            if(!this.validator.mobile) {
                return "disabled";
            }
            if(this.newUser.c_password != this.newUser.password ) {
                return "disabled";
            }
            return false; 
        },
        passwordFieldType() {
            if(this.validator.password) {
                return "";
            }
            return "is-danger"
        },
        passwordFieldMessage() {
            if(this.validator.message.password != "") {
                return this.validator.message.password
            }
            return "";
        },
        emailFieldType() {
            if(this.validator.email) {
                return "";
            }
            return "is-danger"
        },
        emailFieldMessage() {
            if(this.validator.message.email != "") {
                return this.validator.message.email
            }
            return "";
        },
        mobileFieldType() {
            if(this.validator.mobile) {
                return "";
            }
            return "is-danger"
        },
        mobileFieldMessage() {
            if(this.validator.message.mobile != "") {
                return this.validator.message.mobile
            }
            return "";
        },
    },
    methods: {
        validateEmail() {
            axios.post('users/validate-email',{ email: this.newUser.email}).then((response) => {
                if(response.data.success) {
                    this.validator.email = true;
                    this.validator.message.email = "";
                } else {
                    this.validator.email = false;
                    this.validator.message.email = this.$t("message.error."+response.data.msg);
                }
            })
        },
        validateMobile() {
            axios.post('users/validate-mobile',{ mobile: this.newUser.mobile}).then((response) => {
                if(response.data.success) {
                    this.validator.mobile = true;
                    this.validator.message.mobile = "";
                } else {
                    this.validator.mobile = false;
                    this.validator.message.mobile =  this.$t("message.error."+response.data.msg);
                }
            })
        },
        validateFirstPassword() {
            if(this.newUser.c_password != "") {
                this.validatePassword();
            }
        },
        validatePassword() {
           if(this.newUser.password == this.newUser.c_password && this.newUser.password.length > 5) {    
                this.validator.password = true;
                this.validator.message.password = "";
            } 
            else {
                this.validator.password = false;
                this.validator.message.password = this.$t("message.error.password-mismatch");
                if(this.newUser.password.length < 5) {
                    this.validator.message.password = this.$t("message.error.password-too-short");
                }
            }
        },
        register() {
            if(this.newUser.first_name == "") {
                this.toastFailure("Etunimi ei voi olla tyhjä!")
                return
            } 
            if(this.newUser.last_name == "") {
                this.toastFailure("Sukunimi ei voi olla tyhjä!")
                return
            } 
            if(this.newUser.mobile == "") {
                this.toastFailure("Puhellinumero ei voi olla tyhjä!")
                return
            } 
            if(this.newUser.email == "") {
                this.toastFailure("Sähköpostiosoite ei voi olla tyhjä!")
                return
            } 
            if(this.newUser.password == "") {
                this.toastFailure("Salasana ei voi olla tyhjä!")
                return
            } 
            axios.post('/users', this.newUser).then((response) => {
                if(response.data.success) {
                    this.toastSuccess("Käyttäjä luotu onnistuneesti!");
                    this.$emit('userCreated',response.data.uuid);
                }
                else {
                    this.toastFailure("Käyttäjän luonti epäonnistui!");
                }
            })
             
        }
    }
}
</script>
<style>
.errormessage {
    display: block;
    color: red;
    width: 100%;
    font-size: 0.75rem;
    margin-top: -0.75rem;
}
.help.is-danger {
    color: red !important;
}
</style>